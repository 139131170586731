import { render } from "./empty.vue?vue&type=template&id=5013e4c4&scoped=true"
import script from "./empty.vue?vue&type=script&lang=js"
export * from "./empty.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-5013e4c4"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5013e4c4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5013e4c4', script)) {
    api.reload('5013e4c4', script)
  }
  
  module.hot.accept("./empty.vue?vue&type=template&id=5013e4c4&scoped=true", () => {
    api.rerender('5013e4c4', render)
  })

}

script.__file = "src/views/static/about/empty.vue"

export default script