<template>

</template>

<script>
import { useRouter, useRoute } from 'vue-router'

export default {
  name: "empty",
  setup(){
    const router =useRouter()
    const urlName= sessionStorage.getItem('fromUrl')
    router.replace({
       name:'shop'
    })
    return{}
  }
}
</script>

<style scoped>

</style>
